import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { Button } from "@/components/Button";
import HubspotContactForm from "@/components/HubspotContactForm";
import SendolaBlackLogo from "@/assets/Img/sendola-black-logo.png";
import SendolaPay from "@/assets/Img/logo-sendola-pay.png";
import { Modal } from "@/components/Modal";
import classNames from "classnames";
import { Link } from "react-router-dom";

const menuItem =
  "flex w-full gap-2 items-center py-3 text-black hover:text-primary-600 cursor-pointer border-b-[1px] border-b-gray-600 last:border-b-0";

const MAIN_WEBSITE_URL = import.meta.env.VITE_MAIN_WEBSITE_DOMAIN;

export const Header = () => {
  const [t] = useTranslation("global");
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuTouched, setMenuTouched] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showNavItems, setShowNavItems] = useState<boolean>(true);
  const [isTransferModalOpen, setIsTransferModalOpen] =
    useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loadingTransferForm, setLoadingTransferForm] =
    useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [scrollDirection, setScrollDirection] = useState<"down" | "up">("up");
  const [visible, setVisible] = useState(true);
  const [showOptionsMenu, setShowOptionsMenu] = useState<boolean>(false);

  const openModal = () => {
    setLoadingForm(true);
    setFormSubmitted(false);
    setIsModalOpen(true);
  };
  const openRequestTransferModal = () => {
    setLoadingTransferForm(true);
    setIsTransferModalOpen(true);
  };

  const ref = useOutsideClick(() => {
    if (menuOpen) setMenuOpen(false);
  });
  const menuRef = useOutsideClick(() => {
    if (showOptionsMenu) setShowOptionsMenu(false);
  });

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? "down" : "up";

      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
        setVisible(direction === "up");
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener("scroll", updateScrollDirection);

    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      if (scrollY > 600) setShowNavItems(false);
      else setShowNavItems(true);
    };

    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!visible) {
      setMenuOpen(false);
    }
  }, [visible]);

  return (
    <>
      <nav
        className={classNames(
          "z-50 sticky top-0 transition-all duration-200 ease-in",
          {
            "-translate-y-20 lg:translate-y-0": !visible,
            "translate-y-0": visible,
          }
        )}
      >
        <div
          className={classNames(
            "w-full flex justify-between items-center transition-all bg-gradient-to-br from-primary-100 to-white",
            {
              "h-16 px-4 py-2": true, // Mobile styles
              "lg:px-8 lg:h-20": true, // Desktop styles
            }
          )}
        >
          <div className="flex gap-4 md:gap-10 items-center">
            <img
              className="h-9 sm:h-10 md:h-12 xl:h-14 w-auto object-contain select-none"
              src={SendolaPay}
              alt="Sendola's Logo"
            />

            <div className="flex h-full items-center gap-2 xs:gap-6">
              <Link to={MAIN_WEBSITE_URL}>
                <h1 className="text-sm xl:text-base hover:text-primary-600 flex-shrink-0 text-black font-[500]">
                  {t("Header.Personal")}
                </h1>
              </Link>
              <Link to="/">
                <h1 className="text-sm xl:text-base flex-shrink-0 text-primary-600 font-[500]">
                  {t("Header.Business")}
                </h1>
              </Link>
            </div>
          </div>

          <div
            ref={ref}
            className="flex items-center gap-2 justify-end lg:hidden"
          >
            <Bars3Icon
              className={classNames(
                "w-10 h-10 rounded text-black cursor-pointer lg:hover:bg-gray-950 p-2 flex-shrink-0",
                {
                  "bg-gray-300": menuTouched,
                }
              )}
              onClick={() => setMenuOpen(!menuOpen)}
              onTouchStart={() => setMenuTouched(true)}
              onTouchEnd={() => setMenuTouched(false)}
            />
          </div>

          <div
            className={classNames(
              "hidden h-full lg:flex items-center text-black font-[500]",
              {
                "gap-8": showNavItems,
                "gap-0": !showNavItems,
              }
            )}
          >
            <div className="flex items-center gap-4 transition-all duration-200">
              <div
                className="hidden relative h-full lg:flex items-center gap-4"
                onMouseEnter={() => setShowOptionsMenu(true)}
                onMouseLeave={() => setShowOptionsMenu(false)}
              >
                <div
                  className={
                    "px-4 py-1 rounded-lg border-[1px] border-transparent hover:bg-gray-500/10 cursor-pointer select-none"
                  }
                >
                  <h1
                    ref={menuRef}
                    className="text-black hover:border-gray-500 font-[500]"
                  >
                    {t("SendolaBlack.Header.Solutions")}
                  </h1>
                </div>

                <div
                  className={classNames(
                    "w-fit min-w-52 max-w-80 flex flex-col rounded-lg border-[1px] border-gray-300 bg-gradient-to-br from-primary-100 to-white absolute top-16 left-0 shadow-lg overflow-hidden transition-all",
                    {
                      "max-h-0 border-none": !showOptionsMenu,
                      "max-h-96": showOptionsMenu,
                    }
                  )}
                >
                  <a
                    href={`${window.location.origin + window.location.pathname}#why-choose-us`}
                    className="flex w-full gap-2 items-center py-2 px-4 hover:text-primary-600 cursor-pointer border-b-gray-600 first:pt-4 last:pb-4"
                  >
                    <p className="text-sm">
                      {t("SendolaBlack.Header.Solutions")}
                    </p>
                  </a>
                  <a
                    href={`${window.location.origin + window.location.pathname}#boost-savings`}
                    className="flex w-full gap-2 items-center py-2 px-4 hover:text-primary-600 cursor-pointer border-b-gray-600 first:pt-4 last:pb-4"
                  >
                    <p className="text-sm">
                      {t("SendolaBlack.Header.Savings")}
                    </p>
                  </a>
                  <a
                    href={`${window.location.origin + window.location.pathname}#faqs`}
                    className="flex w-full gap-2 items-center py-2 px-4 hover:text-primary-600 cursor-pointer border-b-gray-600 first:pt-4 last:pb-4"
                  >
                    <p className="text-sm">{t("SendolaBlack.Header.FAQs")}</p>
                  </a>
                </div>
              </div>

              <Button
                className="!bg-primary-500 !border-0 shadow-lg shadow-primary-100 hover:opacity-90 !px-6"
                size="sm"
                text={t("SendolaBlack.Header.ApplyButton")}
                onClick={openModal}
              />
              <Button
                variant="secondary"
                className="!bg-white border-white text-primary-500 shadow-lg shadow-primary-100 hover:bg-white/10 !px-6"
                size="sm"
                text={t("SendolaBlack.Header.RequestTransfer")}
                onClick={openRequestTransferModal}
              />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "absolute overflow-hidden transition-all w-max min-w-60 rounded-b-lg flex-col bg-gradient-to-br from-primary-100 to-white shadow-lg px-4 top-16 right-0 z-20 box-border",
            {
              "max-h-80": menuOpen,
              "max-h-0": !menuOpen,
            }
          )}
        >
          <a
            href={`${window.location.origin + window.location.pathname}#why-choose-us`}
            className={menuItem}
          >
            <p className="text-sm lg:text-base">
              {t("SendolaBlack.Header.Solutions")}
            </p>
          </a>
          <a
            href={`${window.location.origin + window.location.pathname}#boost-savings`}
            className={menuItem}
          >
            <p className="text-sm lg:text-base">
              {t("SendolaBlack.Header.Savings")}
            </p>
          </a>
          <a
            href={`${window.location.origin + window.location.pathname}#faqs`}
            className={menuItem}
          >
            <p className="text-sm lg:text-base">
              {t("SendolaBlack.Header.FAQs")}
            </p>
          </a>
        </div>
      </nav>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="w-full flex flex-col items-center">
          {!formSubmitted && (
            <img
              className="w-48 md:w-80 mb-10"
              src={SendolaBlackLogo}
              alt="Sendola black's logo"
            />
          )}
          {loadingForm && (
            <p className="w-full text-center">{t("Loading")}...</p>
          )}
          <HubspotContactForm
            region="na1"
            portalId="44207752"
            formId="8338283d-2d9c-43c1-ad9d-dedd8617ea5c"
            onReady={() => {
              setLoadingForm(false);
            }}
            onSubmit={() => {
              setFormSubmitted(true);
            }}
          />
        </div>
      </Modal>

      <Modal
        open={isTransferModalOpen}
        onClose={() => setIsTransferModalOpen(false)}
      >
        <div className="w-full flex flex-col items-center">
          {loadingTransferForm && (
            <p className="w-full text-center">{t("Loading")}...</p>
          )}
          <HubspotContactForm
            region="na1"
            portalId="44207752"
            formId="8a2e837d-17dc-409b-a84a-6875f1de4ef6"
            onReady={() => {
              setLoadingTransferForm(false);
            }}
          />
        </div>
      </Modal>
    </>
  );
};
