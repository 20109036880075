export const AmericaAvailableCountries = [
  "MX",
  "CO",
  "CA",
  "EC",
  "GT",
  "BR",
  "HN",
  "AR",
];

export const EuropeAvailableCountries = [
  "GB", "HR", "FR", "LV", "PT", "CY", 
  "DE", "LI", "ES", "CZ", "GR", "LT",
  "AT", "DK", "HU", "LU", "BE", "EE",
  "IE", "MT", "BG", "FI", "IT", "MC",
  "NL", "CH", "NO", "PL", "SK", "SI",
  "SE"
];

export const AfricaAvailableCountries = ["ZA"];

export const OceaniaAvailableCountries = ["AU"];

export const ComingCountries = [
  "ZA",
  "PH",
  "IN",
  "VN",
  "UY",
  "MY",
  "KR",
  "SG",
  "PE",
];
